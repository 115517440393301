<template>
  <div>
    <customer-details>
      <template v-slot:default="{id}">
        <div class="mx-5 my-5" v-if="id!==0">
          <v-form ref="form">
            <v-card class="px-10 py-10" >
              <customer-credit-card :clientId="id" />
            </v-card>
          </v-form>
        </div>
      </template>
    </customer-details>
  </div>
</template>

<script>
import CustomerDetails from './CustomerDetails.vue';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import CustomerCreditCard from '@/components/DataItem/CustomerCreditCard.vue';

export default {
  components: {
    CustomerDetails,
    CustomerCreditCard,
  },
  name: 'CustomerCreditCardTab',
  data() {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
    };
  },
};
</script>

<style lang="scss" scoped>
</style>