<template>
  <div class="customer-credit-card">
    <h4 class="mb-2">{{ label }}<span v-if=card>（更新日時：{{ card.createdAt | formatDateTime }})</span></h4>
    <div v-if="cardInfo">
      <v-row v-for="line in cardInfo" :key="line.label">
        <v-col cols="6">{{line.label}}:</v-col>
        <v-col cols="6">{{line.value || '(未取得)'}}</v-col>
      </v-row>
    </div>
    <div v-else>
      <p>カードは登録されていません。</p>
    </div>
  </div>
</template>

<script>
import { cardExpireToDate } from '@/utils/dateUtil'
export default {
  props: {
    card: Object,
    label: String,
  },
  computed: {
    cardInfo () {
      if (this.card) {
        return [
          {label: 'カード名義', value: this.card.holderName},
          {label: 'カード番号', value: this.card.cardNo},
          {label: 'カード有効期限', value: cardExpireToDate(this.card.expire)},
          {label: 'カード仕向先ID', value: this.card.forward},
          {label: 'カード仕向先会社名', value: this.card.forwardName},
          {label: 'GMO会員ID', value: this.card.duplicatedClientId ?? this.card.clientId},
        ]
      } else {
        return null
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.customer-credit-card {
  width: 400px;
}
</style>
