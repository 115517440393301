<template>
  <div class="d-flex">
    <CustomerCreditCardItem :card="savedCard" :label="`最新の登録内容`" />
    <CustomerCreditCardItem :card="previousCard" :label="`前回の登録内容`" />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { cardExpireToDate } from '@/utils/dateUtil'
import CustomerCreditCardItem from './CustomerCreditCardItem.vue';
export default {
    props: {
        clientId: Number
    },
    data() {
        return {
            savedCard: null,
            previousCard: null
        };
    },
    computed: {
        cardInfo() {
            if (this.savedCard) {
                return [
                    { label: 'カード名義', value: this.savedCard.holderName },
                    { label: 'カード番号', value: this.savedCard.cardNo },
                    { label: 'カード有効期限', value: cardExpireToDate(this.savedCard.expire) },
                    { label: 'カード仕向先ID', value: this.savedCard.forward },
                    { label: 'カード仕向先会社名', value: this.savedCard.forwardName },
                    { label: 'GMO会員ID', value: this.savedCard.duplicatedClientId ?? this.savedCard.clientId },
                ];
            }
            else {
                return null;
            }
        }
    },
    apollo: {
        savedCard: {
            query: gql `query($clientId: Int!) {
        savedCardV2(clientId: $clientId) {
          currentCard { ...cardInfo }
          previousCard { ...cardInfo }
        }
      }
      fragment cardInfo on RegisteredCC {
        id
        holderName
        cardNo
        expire
        forward
        forwardName
        duplicatedClientId
        clientId
        createdAt
      }
      `,
            variables() {
                return { clientId: this.clientId };
            },
            result({ data }) {
                this.savedCard = data.savedCardV2.currentCard;
                this.previousCard = data.savedCardV2.previousCard;
            }
        }
    },
    components: { CustomerCreditCardItem }
}
</script>

<style lang="scss" scoped>

</style>